import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

const GenCombo = (props) => {
  return (
    <InputGroup
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
      }}
    >
      <InputGroup.Text style={{ width: "12rem" }}>
        {props.placeholder}
      </InputGroup.Text>
      <DropdownButton
        id="dropdown-basic-button"
        title={props.value}
        variant="outlin-primary"
      >
        {props.values.map((val, idx) => (
          <Dropdown.Item
            key={idx}
            onClick={(e) => props.onSelect(e)}
            id={val.id}
          >
            {val.key === undefined ? val.value : `${val.key} - ${val.value}`}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      {props.error && (
        <Form.Text style={{ textArea: "red" }}>{props.error}</Form.Text>
      )}
    </InputGroup>
  );
};

export default GenCombo;
