import React from "react";
import ChartistGraph from "react-chartist";
import { Card, Container, Row, Col } from "react-bootstrap";
import { FiUsers } from "react-icons/fi";
import { RiNurseLine } from "react-icons/ri";
import { AiOutlineOrderedList } from "react-icons/ai";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { Timestamp } from "firebase/firestore";
import moment from "moment";

const Dashboard = (props) => {
  const buildCard = (header, icon, mainLabel, labels) => {
    return (
      <Card style={{ height: "100%", color: "#555555" }}>
        <Card.Header style={{ textAlign: "center", fontWeight: 400 }}>
          {header}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {icon}
              </div>
            </Col>
            <Col>
              <div className="numbers">
                <label style={{ fontWeight: 500, fontSize: 30 }}>
                  {mainLabel}
                </label>
                {labels.map((lb, idx) => {
                  return (
                    <Row>
                      <label key={idx} style={{ fontWeight: 100 }}>
                        {lb}
                      </label>
                    </Row>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const buildIncomChart = () => {
    return (
      <div className="ct-chart" id="chartActivity">
        <ChartistGraph
          data={{
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "Mai",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            series: [
              [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
              [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695],
            ],
          }}
          type="Line"
          options={{
            seriesBarDistance: 10,
            axisX: {
              showGrid: false,
            },
            height: "245px",
          }}
          responsiveOptions={[
            [
              "screen and (max-width: 640px)",
              {
                seriesBarDistance: 5,
                axisX: {
                  labelInterpolationFnc: function (value) {
                    return value[0];
                  },
                },
              },
            ],
          ]}
        />
      </div>
    );
  };

  const getLastMonthRecords = (table) => {
    try {
      if (table.length === undefined) return "";
      const monthBefore = moment(Date.now()).add(-1, "M");
      const res = table.filter(
        (e) =>
          e.creationTime > Timestamp.fromDate(new Date(monthBefore)) &&
          e.creationTime <= Timestamp.fromDate(new Date(Date.now()))
      );
      return res?.length;
    } catch (err) {
      alert(err);
    }
  };

  const getTodayRecords = (table) => {
    try {
      if (table.length === undefined) return "";
      const today = getTodayDate();
      const res = table.filter((e) => e.creationTime >= today);
      return res?.length;
    } catch (err) {
      alert(err);
    }
  };

  const getTodayDate = () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return Timestamp.fromDate(now);
  };

  const getIncome = (table, fromDate) => {
    try {
      if (Object.keys(table).length === 0) return 0;
      const res = table.filter((e) => e.creationTime >= fromDate);
      const incoms = Object.values(res).reduce(
        (price, value) => +price + +value.paymentPrice,
        0
      );

      return incoms;
    } catch (err) {
      alert(err);
    }
  };

  const allIncome = getIncome(
    props.orders,
    Timestamp.fromDate(new Date("01-01-1970"))
  );
  const monthlyIncome = getIncome(
    props.orders,
    Timestamp.fromDate(new Date(moment(Date.now()).add(-1, "M")))
  );
  const todayIncome = getIncome(props.orders, getTodayDate());
  return (
    <Container fluid style={{ color: "#555555" }}>
      <Row>
        <Col>
          {buildCard(
            "SERVICE PROVIDERS",
            <RiNurseLine size={60} />,
            props.SPs.length,
            [
              getLastMonthRecords(props.SPs) + " last month",
              getTodayRecords(props.SPs) + " today",
            ]
          )}
        </Col>
        <Col>
          {buildCard("CLIENTS", <FiUsers size={60} />, props.clients.length, [
            getLastMonthRecords(props.clients) + " last month",
            getTodayRecords(props.clients) + " today",
          ])}
        </Col>
        <Col>
          {buildCard(
            "ORDERS",
            <AiOutlineOrderedList size={60} />,
            props.orders.length,
            [
              getLastMonthRecords(props.orders) + " last month",
              getTodayRecords(props.orders) + " today",
            ]
          )}
        </Col>
        <Col>
          {buildCard("INCOMS", <FaRegMoneyBillAlt size={60} />, allIncome, [
            monthlyIncome + " last month",
            todayIncome + " today",
          ])}
        </Col>
      </Row>
      <br />
      <Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">2022 Sales</Card.Title>
                <p className="card-category">All products including Taxes</p>
              </Card.Header>
              <Card.Body>{buildIncomChart()}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default Dashboard;
