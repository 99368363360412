import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyAR80n4ykXMbbAQzYrugZLvkIsNbYGnQJc",
  authDomain: "qure-bfd8a.firebaseapp.com",
  projectId: "qure-bfd8a",
  storageBucket: "qure-bfd8a.appspot.com",
  messagingSenderId: "240220370677",
  appId: "1:240220370677:web:be952f3e7bb1ad4b8837bc",
  measurementId: "G-8EPSJPTFKP"
};

  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);
  export const db = getFirestore(app);

  // *** encript this keys vara with .env file !!