import React, { useState } from 'react'
import * as loginLogic from './LoginLogic'
import styles from './Login.module.scss'

const Login = () => {
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    
    return(
        <>
        <div className={styles.container}>
            <div className={styles.login}>
                <h3> Login </h3>
                <input
                    placeholder="Email..."
                    onChange={(event) => {
                    setLoginEmail(event.target.value);
                    }}
                />
                <input type='password'
                    placeholder="Password..."
                    onChange={(event) => {
                    setLoginPassword(event.target.value);
                    }}
                    />
                <button onClick={()=>loginLogic.login(loginEmail, loginPassword)}> Login</button>
            </div>
        </div>
      </>
    );
}

export default Login;