import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Button,
  Stack,
} from "react-bootstrap";
import DisplayDialog from "../DisplayDialog";
import GenInputText from "../GenInputText";
import GenCombo from "../GenCombo";

const GenInputForm = (props) => {
  const newMode = props.values == null;
  const [values, setValues] = useState(
    newMode
      ? props.inputs.reduce(
          (acc, cur) => ({ ...acc, [cur.name]: cur.defaultValue }),
          {}
        )
      : props.values
  );
  const [errors, setErrors] = useState({});
  const [askBeforeDelete, setAskBeforeDelete] = useState(false);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const findFormErrors = async () => {
    let errs = {};
    await props.inputs.map((input) => {
      if (input.validate !== undefined) {
        const res = input.validate(values[input.name]);
        if (res !== "") {
          errs = { ...errs, [input.name]: res };
        }
      }
      return true;
    });
    return errs;
  };

  const genInputText = (input) => {
    return (
      <GenInputText
        {...input}
        key={input.id}
        value={newMode ? input.defaultValue : props.values[input.name]}
        onChange={onChange}
        readOnly={input.readOnly}
        error={errors[input.name]}
      />
    );
  };

  const genDropDown = (input) => {
    return (
      <GenCombo
        key={input.id}
        placeholder={input.placeholder}
        error={errors[input.name]}
        value={values[input.name]}
        values={input.values}
        onSelect={(e) => {
          setValues({
            ...values,
            [input.name]: e.target.text,
          });
        }}
      />
    );
  };

  const areasDropDown = (input) => {
    return (
      <GenCombo
        key={input.id}
        placeholder={input.placeholder}
        error={errors[input.name]}
        value={
          values.areaId === undefined || values.areaId === ""
            ? ""
            : props.areas.find((a) => a.id === values.areaId)?.getString()
        }
        values={props.areas.map((v) => {
          return { key: v.region, value: v.town, id: v.id };
        })}
        onSelect={(e) => {
          setValues({
            ...values,
            areaId: e.target.id,
            area: e.target.text,
          });
        }}
      />
    );
  };

  const servicesDropDown = (input) => {
    return (
      <GenCombo
        key={input.id}
        placeholder={input.placeholder}
        error={errors[input.name]}
        value={
          values.serviceId === undefined || values.serviceId === ""
            ? ""
            : props.services.find((a) => a.id === values.serviceId)?.getString()
        }
        values={props.services.map((v) => {
          return { key: v.category, value: v.name, id: v.id };
        })}
        onSelect={(e) => {
          setValues({
            ...values,
            serviceId: e.target.id,
            service: e.target.text,
          });
        }}
      />
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        if (Object.keys(errors).length > 0) setErrors({});
        if (newMode) setValues({});
        props.hide();
      }}
      style={{ zIndex: askBeforeDelete ? 1 : 4000 }}
    >
      <ModalHeader closeButton>
        <ModalTitle>
          {newMode ? `New ${props.title}` : `Update ${props.values.name}`}
        </ModalTitle>
      </ModalHeader>

      <ModalBody>
        <Stack gap={2}>
          {props.inputs.map((input) =>
            input.type === "dropdown"
              ? genDropDown(input)
              : input.name === "area"
              ? areasDropDown(input)
              : input.name === "service"
              ? servicesDropDown(input)
              : genInputText(input)
          )}
        </Stack>
      </ModalBody>

      {askBeforeDelete && (
        <DisplayDialog
          onOk={() => {
            setAskBeforeDelete(false);
            props.onSubmit(values, true);
            props.hide();
          }}
          onCancel={() => setAskBeforeDelete(false)}
          title="Delete item"
          content={`Delete the item ${props.values.name}?`}
        ></DisplayDialog>
      )}

      <ModalFooter>
        <Button
          variant="outline-primary"
          onClick={async (e) => {
            e.preventDefault();
            const errs = await findFormErrors();
            if (Object.keys(errs).length > 0) {
              setErrors(errs);
              return;
            }

            props
              .onSubmit(values, false)
              .then((res) => (res ? props.hide() : null));
          }}
        >
          Submit
        </Button>
        <Button
          variant="outline-dark"
          onClick={() => setAskBeforeDelete(true)}
          disabled={newMode}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default GenInputForm;
