import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function GenInputText(props) {
  return (
    <>
      {
        <InputGroup>
          <InputGroup.Text style={{ width: "12rem" }}>
            {props.placeholder}
          </InputGroup.Text>
          <Form.Control
            className="bg-light border"
            type={props.type}
            name={props.name}
            onChange={props.onChange}
            readOnly={props.readOnly}
            defaultValue={props.value}
            style={{ height: 40 }}
            isInvalid={!!props.error}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {props.error}
          </Form.Control.Feedback>
        </InputGroup>
      }
    </>
  );
}

export default GenInputText;
