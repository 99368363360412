import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineHome, AiOutlineOrderedList } from "react-icons/ai";
import { FiUsers, FiLogOut } from "react-icons/fi";
import { RiNurseLine } from "react-icons/ri";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { BiExit } from "react-icons/bi";
import { RiSettings2Line } from "react-icons/ri";
import "./Sidebar.scss";

const sidebarNavItems = [
  {
    display: "Home",
    icon: <AiOutlineHome />,
    to: "/",
    section: "",
  },
  {
    display: "Clients",
    icon: <FiUsers />,
    to: "/clients",
    section: "clients",
  },
  {
    display: "Service Providers",
    icon: <RiNurseLine />,
    to: "/serviceProviders",
    section: "serviceProviders",
  },
  {
    display: "Orders",
    icon: <AiOutlineOrderedList />,
    to: "/orders",
    section: "orders",
  },
  {
    display: "Bills",
    icon: <FaRegMoneyBillAlt />,
    to: "/bills",
    section: "bills",
  },
  {
    display: "Settings",
    icon: <RiSettings2Line />,
    to: "/settings",
    section: "settings",
  },
  {
    display: "Exit",
    icon: <FiLogOut />,
    to: "/exit",
    section: "exit",
  },
];

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [stepHeight, setStepHeight] = useState(0);
  const sidebarRef = useRef();
  const indicatorRef = useRef();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const sidebarItem = sidebarRef.current.querySelector(
        ".sidebar__menu__item"
      );
      indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
      setStepHeight(sidebarItem.clientHeight);
    }, 50);
  }, []);

  // change active index
  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNavItems.findIndex(
      (item) => item.section === curPath
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img alt="" src={require("../../assets/QureLogo.JPG")} />
      </div>
      <div ref={sidebarRef} className="sidebar__menu">
        <div
          ref={indicatorRef}
          className="sidebar__menu__indicator"
          style={{
            transform: `translateX(-50%) translateY(${
              activeIndex * stepHeight
            }px)`,
          }}
        ></div>
        {sidebarNavItems.map((item, index) => (
          <Link to={item.to} key={index} style={{ textDecoration: "none" }}>
            <div
              className={`sidebar__menu__item ${
                activeIndex === index ? "active" : ""
              }`}
            >
              <div className="sidebar__menu__item__icon">{item.icon}</div>
              <div className="sidebar__menu__item__text">{item.display}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
