import {
  collection,
  getDoc,
  updateDoc,
  doc,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase-config";

const STATISTICS_TABLE = "statistics";

// clients
export const subscribeClients = (setClients) => {
  const unsubscribe = onSnapshot(collection(db, "clients"), (snapshot) => {
    if (snapshot.docs.length >= 0) {
      const clients = snapshot.docs.map((doc) => {
        const data = doc.data();
        const area = areas.find((a) => a.id === data.areaId);
        return {
          ...data,
          id: doc.id,
          area: area === undefined ? "" : area.getString(),
        };
      });
      setClients(clients);
    }
  });

  return unsubscribe;
};

// service providers
export const subscribeSPs = (setSPs) => {
  const unsubscribe = onSnapshot(
    collection(db, "service-providers"),
    (snapshot) => {
      if (snapshot.docs.length >= 0) {
        const sps = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
          };
        });
        setSPs(sps);
      }
    }
  );

  return unsubscribe;
};

// orders
export const subscribeOrders = (setOrders) => {
  const unsubscribe = onSnapshot(collection(db, "orders"), (snapshot) => {
    if (snapshot.docs.length >= 0) {
      const orders = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
        };
      });
      setOrders(orders);
    }
  });
  return unsubscribe;
};

// areas
export let areas = [];
export const subscribeAreas = () => {
  const unsubscribe = onSnapshot(collection(db, "areas"), (snapshot) => {
    if (snapshot.docs.length >= 0) {
      areas = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          getString: () => {
            return `${data.region} - ${data.town}`;
          },
        };
      });
    }
  });
  return unsubscribe;
};

// services
export let services = [];
export const subscribeServices = () => {
  const unsubscribe = onSnapshot(collection(db, "services"), (snapshot) => {
    if (snapshot.docs.length >= 0) {
      services = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          getString: () => {
            return `${data.category} - ${data.name}`;
          },
        };
      });
    }
  });
  return unsubscribe;
};

export const afterAddClient = async (client) => {
  const docRef = doc(db, STATISTICS_TABLE, "st");
  const statisticsDoc = await getDoc(docRef);
  const statistics = statisticsDoc.data();

  const date = serverTimestamp();
  let c = statistics.clients;
  if (c === undefined) {
    c = [];
  }
  c.push(date);

  await updateDoc(docRef, {
    clients: { clients: c },
  });
};

export const afterAddSP = async (sp) => {
  const docRef = doc(db, STATISTICS_TABLE, "st");
  const statisticsDoc = await getDoc(docRef);
  const statistics = statisticsDoc.data();

  await updateDoc(docRef, {
    serviceProviders: {
      totalSps:
        statistics.serviceProviders === undefined
          ? 1
          : statistics.serviceProviders.totalserviceProviders + 1,
    },
  });
};

export const afterAddOrder = async (order) => {
  //const docRef = doc(db, STATISTICS_TABLE, "st");
  //await docRef.update({ orders: FieldValue.increment(1) });
  /*const increment = FieldValue.increment(1); // 👈 Use admin
  const docRef = db.collection(STATISTICS_TABLE).doc("st");

  return docRef.update({ orders: increment });*/
  // 👈 Added return
  //const statisticsDoc = await getDoc(docRef);
  //const statistics = statisticsDoc.data();
  /*await updateDoc(docRef, {
    orders: {
      totalOrders: FieldValue.increment(1),
    },
  });*/
};
