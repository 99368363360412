import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";

const DisplayDialog = (props) => {
  return (
    <Modal show={true} style={{ zIndex: 5000 }}>
      <ModalHeader>
        <ModalTitle>{props.title}</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <p>{props.content}</p>
      </ModalBody>

      <ModalFooter>
        <Button variant="outline-primary" onClick={props.onOk}>
          Ok
        </Button>
        <Button variant="outline-dark" onClick={props.onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DisplayDialog;
