// https://github.com/machadop1407/react-firebase-authentication/blob/main/src/App.js

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../../../firebase-config";

export const register = async (registerEmail, registerPassword) => {
  try {
    const user = await createUserWithEmailAndPassword(
      auth,
      registerEmail,
      registerPassword
    );
    console.log(user);
  } catch (err) {
    alert(err);
  }
};

export const login = async (email, password) => {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password);
    console.log(user);
  } catch (err) {
    alert(err);
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (err) {
    alert(err);
  }
};
