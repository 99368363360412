import React, { useState } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { BsPlusLg } from "react-icons/bs";
import styles from "./ClientsPage.module.scss";
import GenInputForm from "../../components/forms/GenInputForm";
import { areas, afterAddClient } from "../../helpers/firebaseLogic";

const CLIENTS_TABLE = "clients";

const ClientsPage = (props) => {
  const [formOpen, setFormOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [actionType, setActionType] = useState("");

  // grid
  var gridOptions = {
    rowSelection: "multiple",
    onRowDoubleClicked: (event) => onRowDoubleClicked(event),
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onRowDoubleClicked = (e) => {
    setActionType("update");
    setFormOpen(true);
  };

  const getSelectedId = () => {
    if (gridApi === undefined) return "";
    const rows = gridApi.getSelectedRows();
    if (rows.length > 0) return rows[0].id;
    return "";
  };

  const getSelectedData = () => {
    if (actionType === "add") return null;
    try {
      const id = getSelectedId();
      const data = props.clients.find((el) => el.id === id);
      return data;
    } catch (err) {
      alert(err);
    }
    return {};
  };

  // firebase
  const onAdd = (e) => {
    setActionType("add");
    setFormOpen(true);
  };

  const onSubmit = async (entries, deleteObj) => {
    try {
      const payload = {
        name: entries.name,
        midName: entries.midName,
        lastName: entries.lastName,
        clientId: entries.clientId,
        status: entries.status,
        phone: entries.phone,
        address: entries.address,
        email: entries.email,
        areaId: entries.areaId,
      };

      if (deleteObj) {
        deleteDoc(doc(db, CLIENTS_TABLE, getSelectedId()));
      } else {
        if (actionType === "add") {
          payload.creationTime = serverTimestamp();
          payload.updateTime = serverTimestamp();
          await addDoc(collection(db, CLIENTS_TABLE), payload);
          afterAddClient(payload);
        }
        if (actionType === "update") {
          payload.updateTime = serverTimestamp();
          await updateDoc(
            doc(collection(db, CLIENTS_TABLE), getSelectedId()),
            payload
          );
        }
      }
    } catch (err) {
      alert(err);
      return false;
    }
    return true;
  };

  return (
    <div className={styles.body}>
      {formOpen && (
        <GenInputForm
          title="Client"
          show={formOpen}
          hide={() => setFormOpen(false)}
          inputs={getInputs()}
          values={getSelectedData()}
          areas={areas}
          onSubmit={onSubmit}
        />
      )}
      <div className={styles.header}>
        <BsPlusLg className={styles.plusIcon} size={40} onClick={onAdd}>
          New
        </BsPlusLg>
      </div>
      <div className="ag-theme-alpine" style={{ height: 800, width: "100%" }}>
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={{ sortable: true, filter: true }}
          pagination={true}
          rowData={props.clients}
          columnDefs={getColDefs()}
          gridOptions={gridOptions}
        ></AgGridReact>
      </div>
    </div>
  );
};

const getColDefs = () => {
  return [
    { field: "name", width: 200 },
    { field: "midName", width: 150 },
    { field: "lastName", width: 200 },
    { field: "clientId", width: 150 },
    { field: "status", width: 100 },
    { field: "phone", width: 150 },
    { field: "address", width: 250 },
    { field: "email", width: 200 },
    { field: "area", width: 250 },
  ];
};

const getInputs = () => {
  return [
    {
      id: 1,
      name: "name",
      label: "Name",
      placeholder: "Name",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "User name should not be empty";
        return "";
      },
    },
    {
      id: 2,
      name: "midName",
      label: "Mid Name",
      placeholder: "Mid Name",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 3,
      name: "lastName",
      label: "Last Name",
      placeholder: "Last Name",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Last name should not be empty";
        return "";
      },
    },
    {
      id: 3,
      name: "clientId",
      label: "ID",
      placeholder: "ID",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "")
          return "Identification number should not be empty";
        return "";
      },
    },
    {
      id: 4,
      name: "phone",
      label: "Phone",
      placeholder: "Phone",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Phone number should not be empty";
        return "";
      },
    },
    {
      id: 5,
      name: "address",
      label: "Address",
      placeholder: "Address",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Address should not be empty";
        return "";
      },
    },
    {
      id: 6,
      name: "email",
      label: "Email",
      placeholder: "Email",
      type: "email",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 7,
      name: "area",
      label: "livingArea",
      placeholder: "Living Area",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Living area should not be empty";
        return "";
      },
    },
    {
      id: 9,
      name: "status",
      label: "Status",
      placeholder: "Status",
      type: "dropdown",
      errorMsg: "",
      defaultValue: "Active",
      values: [
        { value: "Active" },
        { value: "Suspended" },
        { value: "Deleted" },
      ],
      readOnly: false,
    },
  ];
};

export default ClientsPage;
