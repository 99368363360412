import React from "react";
import styles from "./Logout.module.scss";
import * as loginLogic from "../login/LoginLogic";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";

const Logout = () => {
  const navigate = useNavigate();

  function logout() {
    navigate("/");
    loginLogic.logout();
  }

  return (
    <Container>
      <Row className="justify-content-md-center" style={{ width: 200 }}>
        <Col>Logout</Col>
        <Col>
          <Button onClick={logout}>Signout</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Logout;
