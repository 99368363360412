import React, { useEffect, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { BsPlusLg } from "react-icons/bs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GenInputForm from "../../components/forms/GenInputForm";

const SERVICES_TABLE = "services";
const AREAS_TABLE = "areas";

const SettingsPage = () => {
  const [serviceGridApi, setServiceGridApi] = useState();
  const [serviceRowData, setServiceRowData] = useState([]);
  const [serviceFormOpen, setServiceFormOpen] = useState(false);

  const [areaGridApi, setAreaGridApi] = useState();
  const [areaRowData, setAreaRowData] = useState([]);
  const [areaFormOpen, setAreaFormOpen] = useState(false);

  const [actionType, setActionType] = useState("");

  // services grid
  var serviceGridOptions = {
    rowSelection: "multiple",
    onRowDoubleClicked: (event) => onServiceRowDoubleClicked(event),
  };

  const onServiceGridReady = (params) => {
    setServiceGridApi(params.api);
  };

  const onServiceRowDoubleClicked = (e) => {
    setActionType("update");
    setServiceFormOpen(true);
  };

  const getServiceSelectedKey = () => {
    if (serviceGridApi === undefined) return "";
    const rows = serviceGridApi.getSelectedRows();
    if (rows.length > 0) return rows[0].key;
    return "";
  };

  const getServiceSelectedData = () => {
    if (actionType === "add") return null;
    try {
      const key = getServiceSelectedKey();
      const data = serviceRowData.find((el) => el.key === key);
      return data;
    } catch (err) {
      alert(err);
    }
    return {};
  };

  // areas grid
  var areaGridOptions = {
    rowSelection: "multiple",
    onRowDoubleClicked: (event) => onAreaRowDoubleClicked(event),
  };

  const onAreaGridReady = (params) => {
    setAreaGridApi(params.api);
  };

  const onAreaRowDoubleClicked = (e) => {
    setActionType("update");
    setAreaFormOpen(true);
  };

  const getAreaSelectedKey = () => {
    if (areaGridApi === undefined) return "";
    const rows = areaGridApi.getSelectedRows();
    if (rows.length > 0) return rows[0].key;
    return "";
  };

  const getAreaSelectedData = () => {
    if (actionType === "add") return null;
    try {
      const key = getAreaSelectedKey();
      const data = areaRowData.find((el) => el.key === key);
      return data;
    } catch (err) {
      alert(err);
    }
    return {};
  };

  // firebase
  const onServiceAdd = (e) => {
    setActionType("add");
    setServiceFormOpen(true);
  };

  const onAreaAdd = (e) => {
    setActionType("add");
    setAreaFormOpen(true);
  };

  const onServiceSubmit = async (entries, deleteObj) => {
    try {
      const payload = {
        name: entries.name,
        category: entries.category,
      };

      if (deleteObj) {
        deleteDoc(doc(db, SERVICES_TABLE, getServiceSelectedKey()));
      } else {
        if (actionType === "add") {
          await addDoc(collection(db, SERVICES_TABLE), payload);
        }
        if (actionType === "update") {
          await updateDoc(
            doc(collection(db, SERVICES_TABLE), getServiceSelectedKey()),
            payload
          );
        }
      }
    } catch (err) {
      alert(err);
      return false;
    }
    return true;
  };

  const onAreaSubmit = async (entries, deleteObj) => {
    try {
      const payload = {
        region: entries.region,
        town: entries.town,
      };

      if (deleteObj) {
        deleteDoc(doc(db, AREAS_TABLE, getAreaSelectedKey()));
      } else {
        if (actionType === "add") {
          await addDoc(collection(db, AREAS_TABLE), payload);
        }
        if (actionType === "update") {
          //const prevData = getAreaSelectedData();
          await updateDoc(
            doc(collection(db, AREAS_TABLE), getAreaSelectedKey()),
            payload
          );
        }
      }
    } catch (err) {
      alert(err);
      return false;
    }
    return true;
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, SERVICES_TABLE),
      (snapshot) => {
        if (snapshot.docs.length === 0) {
          setServiceRowData([getServiceEmptyRow()]);
        } else {
          setServiceRowData(
            snapshot.docs.map((doc) => {
              return { ...doc.data(), key: doc.id };
            })
          );
        }
      }
    );
    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, AREAS_TABLE), (snapshot) => {
      if (snapshot.docs.length === 0) {
        setAreaRowData([getAreaEmptyRow()]);
      } else {
        setAreaRowData(
          snapshot.docs.map((doc) => {
            return { ...doc.data(), key: doc.id };
          })
        );
      }
    });
    return unsubscribe;
  }, []);

  return (
    <Container>
      <Col>
        {serviceFormOpen && (
          <GenInputForm
            title="Service"
            show={serviceFormOpen}
            hide={() => setServiceFormOpen(false)}
            inputs={getServiceInputs()}
            values={getServiceSelectedData()}
            onSubmit={onServiceSubmit}
          />
        )}

        {areaFormOpen && (
          <GenInputForm
            title="Area"
            show={areaFormOpen}
            hide={() => setAreaFormOpen(false)}
            inputs={getAreaInputs()}
            values={getAreaSelectedData()}
            onSubmit={onAreaSubmit}
          />
        )}

        {/* Services Grid */}
        <Row>
          <Col sm={5}>
            <label style={{ padding: "3px", fontSize: "1.3rem" }}>
              Services
            </label>
          </Col>
          <Col>
            <BsPlusLg size={25} onClick={onServiceAdd} />
          </Col>
        </Row>

        <div className="ag-theme-alpine" style={{ height: 250, width: 580 }}>
          <AgGridReact
            onGridReady={onServiceGridReady}
            defaultColDef={{ sortable: true, filter: true }}
            rowData={serviceRowData}
            columnDefs={getServiceColDefs()}
            gridOptions={serviceGridOptions}
          ></AgGridReact>
        </div>

        <div className="mb-3" />

        {/* Areas Grid */}
        <Row>
          <Col sm={5}>
            <label style={{ padding: "3px", fontSize: "1.3rem" }}>Areas</label>
          </Col>
          <Col>
            <BsPlusLg size={25} onClick={onAreaAdd} />
          </Col>
        </Row>

        <div className="ag-theme-alpine" style={{ height: 250, width: 580 }}>
          <AgGridReact
            onGridReady={onAreaGridReady}
            defaultColDef={{ sortable: true, filter: true }}
            rowData={areaRowData}
            columnDefs={getAreaColDefs()}
            gridOptions={areaGridOptions}
          ></AgGridReact>
        </div>
      </Col>
    </Container>
  );
};

const getServiceEmptyRow = () => {
  return {
    key: "0",
    name: "",
    category: "",
  };
};

const getAreaEmptyRow = () => {
  return {
    key: "0",
    region: "",
    town: "",
  };
};

const getServiceColDefs = () => {
  return [
    { field: "_key", hide: true },
    { field: "category", width: 250 },
    { field: "name", width: 250 },
  ];
};

const getAreaColDefs = () => {
  return [
    { field: "_key", hide: true },
    { field: "region", width: 250 },
    { field: "town", width: 250 },
  ];
};

const getServiceInputs = () => {
  return [
    {
      id: 2,
      name: "category",
      label: "Category",
      placeholder: "Category",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Category should not be empty";
        return "";
      },
    },
    {
      id: 1,
      name: "name",
      label: "Name",
      placeholder: "Name",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Name should not be empty";
        return "";
      },
    },
  ];
};

const getAreaInputs = () => {
  return [
    {
      id: 1,
      name: "region",
      label: "Region",
      placeholder: "Region",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Region should not be empty";
        return "";
      },
    },
    {
      id: 2,
      name: "town",
      label: "Town",
      placeholder: "Town",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Town should not be empty";
        return "";
      },
    },
  ];
};

export default SettingsPage;
