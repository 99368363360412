import React, { useEffect, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { BsPlusLg } from "react-icons/bs";
import styles from "./ServiceProvidersPage.module.scss";
import SpInputForm from "../../components/forms/SpInputForm";
import { areas, afterAddSP } from "../../helpers/firebaseLogic";

const SP_TABLE = "service-providers";

const ServiceProviderPage = (props) => {
  const [formOpen, setFormOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [actionType, setActionType] = useState("");

  // grid
  var gridOptions = {
    rowSelection: "multiple",
    onRowDoubleClicked: (event) => onRowDoubleClicked(event),
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onRowDoubleClicked = (e) => {
    setActionType("update");
    setFormOpen(true);
  };

  const getSelectedId = () => {
    if (gridApi === undefined) return "";
    const rows = gridApi.getSelectedRows();
    if (rows.length > 0) return rows[0].id;
    return "";
  };

  const getSelectedData = () => {
    if (actionType === "add") return null;
    try {
      const id = getSelectedId();
      const data = props.SPs.find((el) => el.id === id);
      return data;
    } catch (err) {
      alert(err);
    }
    return {};
  };

  // firebase
  const onAdd = (e) => {
    setActionType("add");
    setFormOpen(true);
  };

  const onSubmit = async (entries, deleteObj) => {
    try {
      const payload = {
        name: entries.name,
        midName: entries.midName,
        lastName: entries.lastName,
        status: entries.status,
        phone: entries.phone,
        address: entries.address,
        email: entries.email,
        pictureUrl: entries.pictureUrl,
        certificationUrl: entries.certificationUrl,
        gradeScore: entries.gradeScore,
        numOfReviews: entries.numOfReviews,
        unreadChats: entries.unreadChats,
        shortTextAboutMe:
          entries.shortTextAboutMe === undefined
            ? ""
            : entries.shortTextAboutMe,
        longTextAboutMe:
          entries.longTextAboutMe === undefined ? "" : entries.longTextAboutMe,
        certipicationText:
          entries.certipicationText === undefined
            ? ""
            : entries.certipicationText,
        areaList: entries.areaList,
      };

      if (deleteObj) {
        deleteDoc(doc(db, SP_TABLE, getSelectedId()));
      } else {
        if (actionType === "add") {
          payload.creationTime = serverTimestamp();
          payload.updateTime = serverTimestamp();
          await addDoc(collection(db, SP_TABLE), payload);
          afterAddSP(payload);
        }
        if (actionType === "update") {
          payload.updateTime = serverTimestamp();
          await updateDoc(
            doc(collection(db, SP_TABLE), getSelectedId()),
            payload
          );
        }
      }
    } catch (err) {
      alert(err);
      return false;
    }
    return true;
  };

  return (
    <div className={styles.body}>
      {formOpen && (
        <SpInputForm
          title="Service Provider"
          show={formOpen}
          hide={() => setFormOpen(false)}
          inputs={getInputs()}
          values={getSelectedData()}
          onSubmit={onSubmit}
          areas={areas}
        />
      )}

      <div className={styles.header}>
        <BsPlusLg className={styles.plusIcon} size={40} onClick={onAdd}>
          New
        </BsPlusLg>
      </div>

      <div className="ag-theme-alpine" style={{ height: 800, width: "100%" }}>
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={{ sortable: true, filter: true }}
          pagination={true}
          rowData={props.SPs}
          columnDefs={getColDefs()}
          gridOptions={gridOptions}
        ></AgGridReact>
      </div>
    </div>
  );
};

const getColDefs = () => {
  return [
    { field: "name", width: 200 },
    { field: "midName", width: 150 },
    { field: "lastName", width: 200 },
    { field: "status", width: 100 },
    { field: "phone", width: 150 },
    { field: "address", width: 250 },
    { field: "email", width: 200 },
    { field: "pictureUrl", width: 150 },
    { field: "certificationUrl", width: 150 },
    { field: "gradeScore", width: 150 },
    { field: "numOfReviews", width: 160 },
    { field: "unreadChats", width: 150 },
    { field: "shortTextAboutMe", width: 200 },
    { field: "longTextAboutMe", width: 200 },
    { field: "certipicationText", width: 200 },
  ];
};

const getInputs = () => {
  return [
    {
      id: 1,
      name: "name",
      label: "Name",
      placeholder: "Name",
      type: "text",
      validate: (val) => {
        if (!val || val === "") return "User name should not be empty";
        return "";
      },
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 2,
      name: "midName",
      label: "Mid Name",
      placeholder: "Mid Name",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 3,
      name: "lastName",
      label: "Last Name",
      placeholder: "Last Name",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Last name should not be empty";
        return "";
      },
    },
    {
      id: 4,
      name: "phone",
      label: "Phone",
      placeholder: "Phone",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Phone should not be empty";
        return "";
      },
    },
    {
      id: 5,
      name: "address",
      label: "Address",
      placeholder: "Address",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Address should not be empty";
        return "";
      },
    },
    {
      id: 6,
      name: "email",
      label: "Email",
      placeholder: "Email",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Email should not be empty";
        return "";
      },
    },
    {
      id: 7,
      name: "pictureUrl",
      label: "Picture Url",
      placeholder: "Picture Url",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 8,
      name: "certificationUrl",
      label: "Certification Url",
      placeholder: "Certification Url",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 9,
      name: "gradeScore",
      label: "GradeS core",
      placeholder: "Grade Score",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 10,
      name: "numOfReviews",
      label: "Num Of Reviews",
      placeholder: "Num Of Reviews",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 11,
      name: "unreadChats",
      label: "Unread Chats",
      placeholder: "Unread Chats",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 12,
      name: "status",
      label: "Status",
      placeholder: "Status",
      type: "dropdown",
      errorMsg: "",
      defaultValue: "Active",
      values: [
        { value: "Active" },
        { value: "Suspended" },
        { value: "Deleted" },
      ],
      readOnly: true,
    },
  ];
};

export default ServiceProviderPage;
