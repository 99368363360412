import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Button,
  Stack,
  Container,
  Col,
  Row,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import DisplayDialog from "../DisplayDialog";
import GenInputText from "../GenInputText";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  collection,
  query,
  where,
  onSnapshot,
  deleteDoc,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { BsPlusLg } from "react-icons/bs";
import GenInputForm from "./GenInputForm";
import { services } from "../../helpers/firebaseLogic";
import GenCombo from "../GenCombo";

const SP_PRICE_LIST_TABLE = "sp-price-list";

const SpInputForm = (props) => {
  const newMode = props.values == null;
  const [values, setValues] = useState(
    newMode
      ? props.inputs.reduce(
          (acc, cur) => ({ ...acc, [cur.name]: cur.defaultValue }),
          {}
        )
      : props.values
  );
  const [errors, setErrors] = useState({});
  const [askBeforeDelete, setAskBeforeDelete] = useState(false);
  const [priceListData, setPriceListData] = useState([]);
  const [actionType, setActionType] = useState("");
  const [formOpen, setFormOpen] = useState(false);
  const [gridApi, setGridApi] = useState();

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const findFormErrors = async () => {
    let errs = {};
    await props.inputs.map((input) => {
      if (input.validate !== undefined) {
        const res = input.validate(values[input.name]);
        if (res !== "") {
          errs = { ...errs, [input.name]: res };
        }
      }
      return true;
    });
    return errs;
  };

  const onServiceAreaChanged = (e, area) => {
    const checked = e.currentTarget.checked;

    var areaList = values["areaList"];
    if (areaList == null) areaList = [];

    if (checked) {
      if (!isAreaInTheList(area.id, areaList)) {
        areaList.push(area.id);
      }
    } else {
      if (isAreaInTheList(area.id, areaList)) {
        areaList.splice(
          areaList.findIndex((e) => e === area.id),
          1
        );
      }
    }

    setValues({
      ...values,
      areaList: areaList,
    });
  };

  const isAreaInTheList = (areaId, areaList) => {
    if (areaList === undefined) return false;
    return areaList.find((a) => a === areaId) !== undefined;
  };

  const getTextArea = (name, desc, rows) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label>{desc}</Form.Label>
        <Form.Control
          as="textarea"
          rows={rows}
          name={name}
          defaultValue={props.values == null ? "" : props.values[name]}
          onChange={onChange}
          error={errors[name]}
        />
      </Form.Group>
    );
  };

  const genDropDown = (input) => {
    return (
      <GenCombo
        key={input.id}
        placeholder={input.placeholder}
        error={errors[input.name]}
        value={values[input.name]}
        values={input.values}
        onSelect={(e) => {
          setValues({
            ...values,
            [input.name]: e.target.text,
          });
        }}
      />
    );
  };

  // grid
  var gridOptions = {
    rowSelection: "multiple",
    onRowDoubleClicked: (event) => onRowDoubleClicked(event),
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onRowDoubleClicked = (e) => {
    setActionType("update");
    setFormOpen(true);
  };

  const getSelectedId = () => {
    if (gridApi === undefined) return "";
    const rows = gridApi.getSelectedRows();
    if (rows.length > 0) return rows[0].id;
    return "";
  };

  const getSelectedPriceListData = () => {
    if (actionType === "add") return null;
    try {
      const id = getSelectedId();
      const data = priceListData.find((el) => el.id === id);
      return data;
    } catch (err) {
      alert(err);
    }
    return {};
  };

  // firebase
  const onAdd = (e) => {
    setActionType("add");
    setFormOpen(true);
  };

  const onSubmit = async (entries, deleteObj) => {
    try {
      const payload = {
        spUID: values.id,
        serviceId: entries.serviceId,
        price_1_to_4: entries.price_1_to_4,
        price_5_to_10: entries.price_5_to_10,
        price_10_plus: entries.price_10_plus,
      };

      if (deleteObj) {
        deleteDoc(doc(db, SP_PRICE_LIST_TABLE, getSelectedId()));
      } else {
        if (actionType === "add") {
          payload.creationTime = serverTimestamp();
          payload.updateTime = serverTimestamp();
          await addDoc(collection(db, SP_PRICE_LIST_TABLE), payload);
        }
        if (actionType === "update") {
          payload.updateTime = serverTimestamp();
          await updateDoc(
            doc(collection(db, SP_PRICE_LIST_TABLE), getSelectedId()),
            payload
          );
        }
      }
    } catch (err) {
      alert(err);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (values.id === undefined) return;

    const collectionRef = collection(db, SP_PRICE_LIST_TABLE);
    const q = query(collectionRef, where("spUID", "==", values.id));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (snapshot.docs.length > 0)
        setPriceListData(snapshot.docs.map((doc) => getDocData(doc)));
    });
    return unsubscribe;
  }, [values.id]);

  const getDocData = (doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      service: services.find((s) => s.id === data.serviceId)?.getString(),
    };
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        if (Object.keys(errors).length > 0) setErrors({});
        if (newMode) setValues({});
        props.hide();
      }}
      size="xl"
      centered
      style={{ zIndex: askBeforeDelete ? 1 : 3000 }}
    >
      <ModalHeader closeButton>
        <ModalTitle>
          {newMode ? `New ${props.title}` : `Update ${props.values.name}`}
        </ModalTitle>
      </ModalHeader>

      <ModalBody>
        <Container
          size={{ height: 300 }}
          style={{ overflow: "scroll", height: 600 }}
        >
          <Row>
            <Col>
              <Stack gap={2}>
                {props.inputs.map((input) =>
                  input.type === "dropdown" ? (
                    genDropDown(input)
                  ) : (
                    <GenInputText
                      {...input}
                      key={input.id}
                      value={
                        newMode ? input.defaultValue : props.values[input.name]
                      }
                      onChange={onChange}
                      readOnly={input.readOnly}
                      error={errors[input.name]}
                    />
                  )
                )}
              </Stack>
              <br />
              <Form.Label>Service Areas</Form.Label>
              {props.areas.map((area, idx) => (
                <InputGroup className="mb-3" key={idx}>
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    checked={isAreaInTheList(area.id, values["areaList"])}
                    onChange={(e) => onServiceAreaChanged(e, area)}
                  />
                  <FormControl
                    aria-label="Text input with checkbox"
                    value={area.getString()}
                    readOnly
                  />
                </InputGroup>
              ))}
            </Col>
            <Col>
              {getTextArea("shortTextAboutMe", "Short text about me", 2)}
              {getTextArea("longTextAboutMe", "Long text about me", 7)}
              {getTextArea(
                "certipicationText",
                "Text about my certification",
                3
              )}

              {formOpen && (
                <GenInputForm
                  title="Price List"
                  show={formOpen}
                  hide={() => setFormOpen(false)}
                  inputs={getInputs()}
                  values={getSelectedPriceListData()}
                  onSubmit={onSubmit}
                  services={services}
                  dontAskBeforDelete={true}
                />
              )}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Label>Price List</Form.Label>
                <BsPlusLg style={{ padding: 4 }} size={35} onClick={onAdd}>
                  New
                </BsPlusLg>
              </div>

              <div
                className="ag-theme-alpine"
                style={{ height: 400, width: "100%" }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  defaultColDef={{ sortable: true, filter: true }}
                  rowData={priceListData}
                  columnDefs={getColDefs()}
                  gridOptions={gridOptions}
                ></AgGridReact>
              </div>
            </Col>
          </Row>
        </Container>
      </ModalBody>

      {askBeforeDelete && (
        <DisplayDialog
          onOk={() => {
            setAskBeforeDelete(false);
            props.onSubmit(values, true);
            props.hide();
          }}
          onCancel={() => setAskBeforeDelete(false)}
          title="Delete item"
          content={`Delete the item ${props.values.name}?`}
        ></DisplayDialog>
      )}

      <ModalFooter>
        <Button
          variant="outline-primary"
          onClick={async () => {
            const errs = await findFormErrors();
            setErrors(errs);
            if (Object.keys(errs).length > 0) return;

            var vals = { ...values };
            if (vals.areaList == null) vals.areaList = [];
            props
              .onSubmit(vals, false)
              .then((res) => (res ? props.hide() : null));
          }}
        >
          Submit
        </Button>
        <Button
          variant="outline-dark"
          onClick={() => setAskBeforeDelete(true)}
          disabled={newMode}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const getColDefs = () => {
  return [
    { field: "service" },
    { field: "price_1_to_4", headerName: "price 1 to 4" },
    { field: "price_5_to_10", headerName: "price 5 to 10" },
    { field: "price_10_plus", headerName: "price 10 plus" },
  ];
};

const getInputs = () => {
  return [
    {
      id: 1,
      name: "service",
      label: "Service",
      placeholder: "Service",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Service should not be empty";
        return "";
      },
    },
    {
      id: 2,
      name: "price_1_to_4",
      label: "Price 1 to 4",
      placeholder: "Price 1 to 4",
      type: "text",
      errorMsg: "",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "")
          return "Price for one quantity should not be empty";
        return "";
      },
    },
    {
      id: 3,
      name: "price_5_to_10",
      label: "Price 5 to 10",
      placeholder: "Price 5 to 10",
      type: "text",
      errorMsg: "",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 4,
      name: "price_10_plus",
      label: "Price 10 Plus",
      placeholder: "Price 10 Plus",
      type: "text",
      errorMsg: "",
      defaultValue: "",
      readOnly: false,
    },
  ];
};

export default SpInputForm;
