import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { BsPlusLg } from "react-icons/bs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import styles from "./OrdersPage.module.scss";
import { Form } from "react-bootstrap";
import GenInputForm from "../../components/forms/GenInputForm";
import { areas, services, afterAddOrder } from "../../helpers/firebaseLogic";

const ORDERS_TABLE = "orders";

const OrdersPage = (props) => {
  const [formOpen, setFormOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [actionType, setActionType] = useState("");
  const [fromDate, setFromDate] = useState(moment(Date.now()).add(-1, "M"));
  const [toDate, setToDate] = useState(moment(Date.now()).add(1, "H"));
  const [filterClientId, setFilterClientId] = useState("");
  const [filterClient, setFilterClient] = useState("");
  const [filterSP, setFilterSP] = useState("");

  const getRowsData = () => {
    try {
      if (props.orders.length === 0) return;
      return props.orders
        .map((order) => getDocData(order))
        .filter((order) => order != null);
    } catch (err) {
      alert(err);
    }
    return [];
  };

  const getDocData = (order) => {
    const created = order.creationTime.seconds;
    const from = Timestamp.fromDate(new Date(fromDate)).seconds;
    const to = Timestamp.fromDate(new Date(toDate)).seconds;

    if (
      (order.clientId?.includes(filterClientId) ||
        order.clientFullName.includes(filterClient) ||
        order.spFullName.includes(filterSP)) &&
      created >= from &&
      created <= to
    ) {
      return {
        ...order,
        id: order.id,
        creationTime: moment(order.creationTime?.toDate()).format(
          "DD-MM-YYYY hh:mm:ss"
        ),
        area: areas.find((a) => a.id === order.areaId)?.getString(),
        service: services.find((s) => s.id === order.serviceId)?.getString(),
      };
    }
  };

  const rowsData = getRowsData();

  const onDatePicker = (event, picker) => {
    setFromDate(picker.startDate._d);
    setToDate(picker.endDate._d);
  };

  // grid
  var gridOptions = {
    rowSelection: "multiple",
    onRowDoubleClicked: (event) => onRowDoubleClicked(event),
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onRowDoubleClicked = (e) => {
    setActionType("update");
    setFormOpen(true);
  };

  const getSelectedId = () => {
    if (gridApi === undefined) return "";
    const rows = gridApi.getSelectedRows();
    if (rows.length > 0) return rows[0].id;
    return "";
  };

  const getSelectedData = () => {
    if (actionType === "add") return null;
    try {
      const id = getSelectedId();
      const data = rowsData.find((el) => el.id === id);
      return data;
    } catch (err) {
      alert(err);
    }
    return {};
  };

  // firebase
  const onAdd = (e) => {
    setActionType("add");
    setFormOpen(true);
  };

  const onSubmit = async (entries, deleteObj) => {
    try {
      const payload = {
        serviceId: entries.serviceId,
        servicePrice: entries.servicePrice,
        quantity: entries.quantity,
        areaId: entries.areaId,
        serviceAddress: entries.serviceAddress,
        orderStatus: entries.orderStatus,
        clientId: entries.clientId,
        clientFullName: entries.clientFullName,
        spFullName: entries.spFullName,
        spGradeScore: entries.spGradeScore,
        paymentPrice: entries.paymentPrice,
        paymentTime: entries.paymentTime,
        comments: entries.comments,
      };

      if (deleteObj) {
        deleteDoc(doc(db, ORDERS_TABLE, getSelectedId()));
      } else {
        if (actionType === "add") {
          payload.creationTime = serverTimestamp();
          payload.updateTime = serverTimestamp();
          await addDoc(collection(db, ORDERS_TABLE), payload);
          afterAddOrder(payload);
        }
        if (actionType === "update") {
          payload.updateTime = serverTimestamp();
          await updateDoc(
            doc(collection(db, ORDERS_TABLE), getSelectedId()),
            payload
          );
        }
      }
    } catch (err) {
      alert(err);
      return false;
    }
    return true;
  };

  const onFilterClientId = (e) => {
    e.preventDefault();
    setFilterClientId(e.target.value);
  };

  const onFilterClient = (e) => {
    e.preventDefault();
    setFilterClient(e.target.value);
  };

  const onFilterSP = (e) => {
    e.preventDefault();
    setFilterSP(e.target.value);
  };

  return (
    <Form>
      <div className={styles.body}>
        {formOpen && (
          <GenInputForm
            title="Orders"
            show={formOpen}
            hide={() => setFormOpen(false)}
            inputs={getInputs()}
            values={getSelectedData()}
            onSubmit={onSubmit}
            areas={areas}
            services={services}
          />
        )}

        <div className={styles.header}>
          <div className={styles.formFilter}>
            <DateRangePicker
              startDate={fromDate}
              endDate={toDate}
              alwaysShowCalendars={true}
              onApply={onDatePicker}
            >
              <button
                className={styles.datesBtn}
                onClick={(e) => e.preventDefault()}
              >
                {moment(fromDate).format("LL")} to {moment(toDate).format("LL")}
              </button>
            </DateRangePicker>

            <Form.Control
              placeholder="Client ID"
              style={{ width: "15rem", marginRight: "5px" }}
              onKeyPress={(e) => e.key === "Enter" && onFilterClientId(e)}
            ></Form.Control>
            <Form.Control
              placeholder="Client name"
              style={{ width: "15rem", marginRight: "5px" }}
              onKeyPress={(e) => e.key === "Enter" && onFilterClient(e)}
            ></Form.Control>
            <Form.Control
              placeholder="SP name"
              style={{ width: "15rem" }}
              onKeyPress={(e) => e.key === "Enter" && onFilterSP(e)}
            ></Form.Control>
          </div>
          <BsPlusLg className={styles.plusIcon} size={25} onClick={onAdd} />
        </div>

        <div className="ag-theme-alpine" style={{ height: 800, width: "100%" }}>
          <AgGridReact
            onGridReady={onGridReady}
            defaultColDef={{ sortable: true, filter: true, resizable: true }}
            pagination={true}
            rowData={rowsData}
            columnDefs={getColDefs()}
            gridOptions={gridOptions}
          ></AgGridReact>
        </div>
      </div>
    </Form>
  );
};

const getColDefs = () => {
  return [
    { field: "creationTime", width: 165 },
    { field: "service", width: 200 },
    { field: "servicePrice", width: 130 },
    { field: "quantity", width: 130 },
    { field: "area", width: 200 },
    { field: "serviceAddress", width: 200 },
    { field: "orderStatus", width: 130 },
    { field: "clientId", width: 150 },
    { field: "clientFullName", width: 200 },
    { field: "spFullName", width: 200 },
    { field: "spGradeScore", width: 150 },
    { field: "paymentPrice", width: 140 },
    { field: "paymentTime", width: 140 },
    { field: "comments", width: 200 },
  ];
};

const getInputs = () => {
  return [
    {
      id: 1,
      name: "service",
      label: "Service",
      placeholder: "Service",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Service should not be empty";
        return "";
      },
    },
    {
      id: 4,
      name: "servicePrice",
      label: "Service Price",
      placeholder: "Service Price",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Service price should not be empty";
        return "";
      },
    },
    {
      id: 4,
      name: "quantity",
      label: "Quantity",
      placeholder: "Quantity",
      type: "text",
      defaultValue: "1",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Service quantity should not be empty";
        return "";
      },
    },
    {
      id: 2,
      name: "area",
      label: "Service Area",
      placeholder: "Service Area",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Service area should not be empty";
        return "";
      },
    },
    {
      id: 3,
      name: "serviceAddress",
      label: "Service Address",
      placeholder: "Service Address",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 5,
      name: "orderStatus",
      label: "Order Status",
      placeholder: "Order Status",
      type: "dropdown",
      errorMsg: "",
      defaultValue: "New",
      values: [
        { value: "New" },
        { value: "Accepted" },
        { value: "Rejected" },
        { value: "Payed" },
        { value: "Payment Rejected" },
        { value: "Service Performed" },
        { value: "Wait For Results" },
        { value: "Done" },
      ],
      readOnly: false,
    },
    {
      id: 6,
      name: "clientId",
      label: "Client ID",
      placeholder: "Client ID",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 7,
      name: "clientFullName",
      label: "Client Full Name",
      placeholder: "Client Full Name",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Client name should not be empty";
        return "";
      },
    },
    {
      id: 8,
      name: "spFullName",
      label: "SP Full Name",
      placeholder: "SP Full Name",
      type: "text",
      defaultValue: "",
      readOnly: false,
      validate: (val) => {
        if (!val || val === "") return "Service provider should not be empty";
        return "";
      },
    },
    {
      id: 9,
      name: "spGradeScore",
      label: "SP Grade Score",
      placeholder: "SP Grade Score",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 10,
      name: "paymentTime",
      label: "Payment Time",
      placeholder: "Payment Time",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 11,
      name: "paymentPrice",
      label: "Payment Price",
      placeholder: "Payment Price",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
    {
      id: 12,
      name: "comments",
      label: "Comments",
      placeholder: "Comments",
      type: "text",
      defaultValue: "",
      readOnly: false,
    },
  ];
};

export default OrdersPage;
