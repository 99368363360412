import "./App.module.scss";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import Blank from "./pages/Blank";
import SettingsPage from "./pages/settings/SettingsPage";
import ClientsPage from "./pages/clients/ClientsPage";
import Dashboard from "./pages/Dashboard";
import OrdersPage from "./pages/orders/OrdersPage";
import ServiceProvidersPage from "./pages/serviceProviders/ServiceProvidersPage";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../src/firebase-config";
import Login from "./components/auth/login/Login";
import Logout from "./components/auth/logout/Logout";

import {
  subscribeClients,
  subscribeSPs,
  subscribeOrders,
  subscribeAreas,
  subscribeServices,
} from "./helpers/firebaseLogic";
//import useMediaQuery from "./helpers/MediaQuery";

function App() {
  const [user, setUser] = useState({});
  const [clients, setClients] = useState({});
  const [SPs, setSPs] = useState({});
  const [orders, setOrders] = useState({});

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  useEffect(() => {
    const unsubscribeClients = subscribeClients(setClients);
    const unsubscribeSPs = subscribeSPs(setSPs);
    const unsubscribeOrders = subscribeOrders(setOrders);
    const unsubscribeAreas = subscribeAreas();
    const unsubscribeServices = subscribeServices();
    return () => {
      unsubscribeClients();
      unsubscribeSPs();
      unsubscribeOrders();
      unsubscribeAreas();
      unsubscribeServices();
    };
  }, []);

  //const isDesktop = useMediaQuery("(min-width: 320px max-eidth: 100px)");

  if (user == null) {
    return <Login />;
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route
              index
              element={
                <Dashboard clients={clients} SPs={SPs} orders={orders} />
              }
            />
            <Route
              path="/clients"
              element={<ClientsPage clients={clients} />}
            />
            <Route
              path="/serviceProviders"
              element={<ServiceProvidersPage SPs={SPs} />}
            />
            <Route path="/orders" element={<OrdersPage orders={orders} />} />
            <Route path="/bills" element={<Blank />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/exit" element={<Logout />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
